/* src/components/SplineBackground.css */
.spline-background {
  position: fixed;
  top: 0;
  margin-top: 8vh;
  padding-top: 9vh;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: auto; /* Allow interactions */
}
