@keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .skill-slider {
    display: flex;
    overflow: hidden;
    position: relative;
  }
  
  .skill-slider .track {
    display: flex;
    width: 200%; /* Adjust to ensure smooth transition */
    animation: scroll 20s linear infinite;
  }
  
  .skill-slider:hover .track {
    animation-play-state: paused;
  }
  
  .item {
    flex: 1 0 20%; /* Adjust the width of each item */
    text-align: center;
  }
  